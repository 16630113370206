import ExpandShowingsControls from './expand_showings_controls';
import Filters from './filterts';
import FullHeightPanels from './full_height_panels';
import Tooltips from './tooltips';
import TwoWayMessaging from './two_way_messaging/two_way_messaging';
import FetchUtils from 'src/utils/fetch_utils';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import DashboardUtils from 'src/utils/dashboard_utils';
import InfiniteLoaderHandlers from '../../shared/infinite_loader/infinite_loader_handlers';

//TODO:: remove all jquery code when bootstrap js is rewritten without jquery
class Dashboard {
  #isShowingsLoading = false;
  #isListingsLoading = false;
  #expandShowingsControls;

  isLinkInPopup(link) {
    return link.closest('.js-popover-content');
  }

  detectTooltipWrap() {
    const tooltips = document.querySelectorAll('.js-tooltip');
    tooltips.forEach(tooltip => {
      const container = tooltip.closest('ul');
      const tooltipMargin = 3;
      if (tooltip.offsetTop > container.offsetTop + tooltipMargin) {
        tooltip.classList.add('wrapped');
      } else {
        tooltip.classList.remove('wrapped');
      }
    });
  }

  handleShowConfirmManuallyModal(e) {
    e.preventDefault();
    const link = e.currentTarget;
    let submit = document.querySelector('.js-confirm-link');
    submit.href = link.dataset.linkUrl;
    submit.method = link.dataset.method;
    document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {content: document.querySelector('#manually-confirm-modal').innerHTML}}));
    return false;
  }

  handleEnableLink(e) {
    const link = e.currentTarget.activeElement;
    if (link) {
      link.classList.remove('disabled');
    }
  }

  handleDisableLink(e) {
    const link = e.currentTarget.activeElement;
    if (link) {
      link.classList.add('disabled');
    }
  }

  handleToggleLinkbarInPopupClick(e) {
    e.preventDefault();
    const l = e.currentTarget;
    const container = l.closest('ul');
    l.closest('li').querySelectorAll('.js-toggle-icon').forEach(icon => {
      icon.classList.toggle('hidden');
    });
    container.querySelectorAll(l.dataset.show).forEach(el => {
      if (!el.classList.contains('js-back-btn')) {
        el.classList.toggle('hidden');
      }
    });
    return false;
  }

  handleToggleLinkbarClick(e) {
    e.preventDefault();
    const l = e.currentTarget;
    const container = l.closest('.posts');
    if (container) {
      container.querySelectorAll(l.dataset.show).forEach(el => {
        el.classList.remove('hidden');
      });
      container.querySelectorAll(l.dataset.hide).forEach(el => {
        el.classList.add('hidden');
      });
  
      $(container).stop(false,true);
      LayoutUtils.highlight(container);
    }
    return false;
  }

  popoverWithLinksOpenedHandler(containerSelector) {
    document.dispatchEvent(new CustomEvent('dropdown:opened'));
    this.initModalLinks();
    document.querySelectorAll('.js-popover-content .js-toggle-link-bars').forEach(l => {
      l.removeEventListener('click', (e) => this.handleToggleLinkbarInPopupClick(e));
      l.addEventListener('click', (e) => this.handleToggleLinkbarInPopupClick(e));
    });

    document.querySelectorAll(containerSelector + ' .js-toggle-link-bars').forEach(l => {
      l.removeEventListener('click', (e) => this.handleToggleLinkbarClick(e));
      l.addEventListener('click', (e) => this.handleToggleLinkbarClick(e));
    });
  }

  //Initialize links for containers which could be updated via ajax
  initLinks(containerSelector = 'body') {
    this.#expandShowingsControls.initExpandIcons(containerSelector);
    document.querySelectorAll(containerSelector + ' .js-popover-link').forEach(link => {
      SMDropdown.init(link, {
        content: link.closest('.js-popover-wrap').querySelector('.js-popover-content').outerHTML,
        onShown: () => {
          this.popoverWithLinksOpenedHandler(containerSelector);
        }
      });
    });

    document.querySelectorAll(containerSelector + ' .js-need-reply-popover-link').forEach(link => {
      SMDropdown.init(link, {
        content: link.closest('.js-popover-wrap').querySelector('.js-popover-content').outerHTML, 
        onShown: () => {
          document.querySelectorAll('.js-change-reply-needed-score').forEach(el => {
            el.addEventListener('click', (e) => {
              e.preventDefault();
              fetch(el.href, {
                method: 'PATCH',
                body: JSON.stringify({reply_needed_score: el.dataset.score}),
                headers: {
                  'x-csrf-token': document.querySelectorAll('meta[name=csrf-token]')[0].getAttributeNode('content').value,
                  'Content-Type': 'application/json; charset=utf-8'
                }
              }).then(FetchUtils.checkResponseStatus).then((resp) => {
                return resp.json();
              }).then(json => {
                if (json.html) {
                  const showing = document.getElementById(`showing_${json.id}`);
                  showing.innerHTML = json.html;
                  LayoutUtils.highlight(showing);
                  document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#showings_box'}}));
                  if (typeof toggleLeadInitial !== 'undefined') {
                    toggleLeadInitial();
                  }
                }
              }).catch((err) => {
                FetchUtils.handleResponseError(err);
              });
              return false;
            });
          });
        }
      });
    });

    const handleToggleLinkbarInPopupClick = e => {
      e.preventDefault();
      const l = e.currentTarget;
      const container = l.closest('ul');
      l.closest('li').querySelectorAll('.js-toggle-icon').forEach(icon => {
        icon.classList.toggle('hidden');
      });
      container.querySelectorAll(l.dataset.show).forEach(el => {
        if (!el.classList.contains('js-back-btn')) {
          el.classList.toggle('hidden');
        }
      });
      return false;
    };

    const handleToggleLinkbarClick = e => {
      e.preventDefault();
      const l = e.currentTarget;
      const container = l.closest('.posts');
      container.querySelectorAll(l.dataset.show).forEach(el => {
        el.classList.remove('hidden');
      });
      container.querySelectorAll(l.dataset.hide).forEach(el => {
        el.classList.add('hidden');
      });

      $(container).stop(false,true);
      LayoutUtils.highlight(container);
      return false;
    };

    document.querySelectorAll(containerSelector + ' .js-toggle-link-bars').forEach(l => {
      l.removeEventListener('click', handleToggleLinkbarClick);
      l.addEventListener('click', handleToggleLinkbarClick);
    });

    document.querySelectorAll('.js-showing-links-wrap .js-confirm-manually-modal').forEach(l => {
      l.removeEventListener('click', this.handleShowConfirmManuallyModal);
      l.addEventListener('click', this.handleShowConfirmManuallyModal);
    });

    document.querySelectorAll(containerSelector + ' .js-toggle-link-bars').forEach(l => {
      l.removeEventListener('click', this.handleToggleLinkbarClick);
      l.addEventListener('click', this.handleToggleLinkbarClick);
    });
  }

  initModalLinks() {
    document.querySelectorAll('.js-popover-content .js-confirm-manually-modal').forEach(l => {
      l.removeEventListener('click', this.handleShowConfirmManuallyModal);
      l.addEventListener('click', this.handleShowConfirmManuallyModal);
    });
  }

  bindEvents() {
    document.addEventListener('ajax:beforeSend', this.handleDisableLink);
    document.addEventListener('ajax:complete', this.handleEnableLink);

    document.addEventListener('dashboard:init-popover-links', () => {
      this.popoverWithLinksOpenedHandler();
    });

    this.initLinks();
    document.addEventListener('html-updated', (e) => {
      document.querySelectorAll('[data-tippy-root]').forEach((tip) => {
        tip.remove();
      });
      document.querySelectorAll('.popover').forEach((popover) => {
        popover.remove();
      });
      this.initLinks(e.detail.container);

      if (e.detail.container === '#showings_box') {
        this.initShowingsTabs();
        this.initLeadFilterSelect();
      }
      if (e.detail.container === '#listings_box') {
        this.initListingsTabs();
      }
    });
    window.addEventListener('resize', () => {
      this.detectTooltipWrap();
    });

    $(document).on('change', 'select.dynamic-submit, .editable_showing_notes', function() {
      Rails.fire($(this).parent('form')[0], 'submit');
    });

    this.initLeadFilterSelect();
  }

  initLeadFilterSelect() {
    const select = document.querySelector('.js-lead-filter');
    if (select) {
      select.addEventListener('change', () => {
        const opt = select.options[select.selectedIndex];
        this.loadShowings(opt.dataset.url);
      });
    }
  }

  initListingsTabs() {
    this.#isListingsLoading = false;
    document.querySelectorAll('.filter #listing-filter a, .filter #listing-mobile-filter a:not(.dropdown-toggle)').forEach(el => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        if (!this.#isListingsLoading) {
          let wrap = document.querySelector('#listing-heading');
          this.#isListingsLoading = true;
          wrap.insertAdjacentHTML('beforeend', '<div class="ajax-loader-small"></div>');
          DashboardUtils.fetchListings({url: $(el).attr('remote-href')}, wrap, () => {
            this.#isListingsLoading = false;
            wrap.querySelector('.ajax-loader-small').remove();
          });
        }
      });
    });
  }

  loadShowings(url) {
    if (!this.#isShowingsLoading) {
      let wrap = document.querySelector('#showing-heading');
      wrap.insertAdjacentHTML('beforeend', '<div class="ajax-loader-small"></div>');
      this.#isShowingsLoading = true;
      DashboardUtils.fetchShowings({url}, wrap.closest('#showings_box'), () => {
        this.#isShowingsLoading = false;
        const loader = document.querySelector('.js-showings-list-view .js-sm-loader');
        if (loader) {
          loader.dataset.url = url;
        }
        wrap.querySelector('.ajax-loader-small').remove();
      });
    }
  }

  initShowingsTabs() {
    document.querySelectorAll('.filter #showing-filter a:not(.dropdown-toggle), .showing-hidden-leads').forEach(el => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        this.loadShowings($(el).attr('remote-href'));
      });
    });
  }

  initMetricsTooltips() {
    const tooltips = document.querySelectorAll('.js-listing-tooltip');
    tooltips.forEach(tooltip => {
      tippy(tooltip, {
        allowHTML: true,
        content: tooltip.dataset.content,
        theme: 'default'
      });
    });
  }

  initNpsSurvey() {
    const user = JSON.parse(document.querySelector('.js-dashboard').dataset.user);
    var delighted_options = {
      name: user.name,
      email: user.email,
      properties: {
        user_id: user.id,
        account_id: user.account_id
      }
    };

    delighted.survey(delighted_options);
  }

  initTips(data) {
    const initNpsSurvey = this.initNpsSurvey;
    if (data.tipPopup && data.tipPopup != ''){
      var tipData = {};
      if (data.tipPreview) {
        tipData = { tip_preview: data.tipPreview };
      }
    
      var xhrTip = $.get(data.newTipUrl, tipData);

      if (data.displayNpsSurvey && JSON.parse(data.displayNpsSurvey)) {
        xhrTip.fail(initNpsSurvey);
        xhrTip.done(function (data) {
          // There is no tip to display if data is empty
          if (data === '') {
            initNpsSurvey();
          }
        });
      } 
    }
  }

  init(dashboard) {
    this.detectTooltipWrap();    
    this.#expandShowingsControls = new ExpandShowingsControls();
    this.#expandShowingsControls.init();
    new Filters().init(dashboard);

    this.bindEvents();
    this.initShowingsTabs();
    this.initListingsTabs();
    this.initMetricsTooltips();
    this.initTips(dashboard.dataset);

    new FullHeightPanels().init();
    new TwoWayMessaging().init();  
    new Tooltips().init();
    new InfiniteLoaderHandlers().init('.js-showings-infinite-list', DashboardUtils.fetchShowings);
    new InfiniteLoaderHandlers().init('.js-listings-infinite-list', DashboardUtils.fetchListings);

    if (dashboard.dataset.editShowingPopup && JSON.parse(dashboard.dataset.editShowingPopup)) {
      document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {url: dashboard.dataset.editShowingUrl}}));
    }

    if (dashboard.dataset.listingUpdatesPopup && JSON.parse(dashboard.dataset.listingUpdatesPopup)) {
      const alertLink = document.querySelector('#required-listing-updates-alert-my a');
      if (alertLink) {
        document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {
          url: alertLink.href
        }}));
      }
    }

    if (dashboard.dataset.popupPathInfo) {
      document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {url: dashboard.dataset.popupPathInfo}}));
    }
    
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const dashboard = document.querySelector('.js-dashboard');
  if (dashboard) {
    new Dashboard().init(dashboard);
  }
});