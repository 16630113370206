import FetchUtils from 'src/utils/fetch_utils';

class DashboardUtils {
  static hideLoader(container) {
    const loader = container.querySelector('.js-sm-loader');
    if (loader) {
      container.querySelector('.js-sm-loader').remove();
    }
  }


  static getUrl(urlData, container) {
    let url = '';
    if (urlData.url) {
      url = urlData.url;
    } else {
      const filter = container.previousElementSibling;
      if (container.getAttribute('remote-href')) {
        url = container.getAttribute('remote-href');
      }else if (filter.querySelector('a.current').getAttribute('remote-href')) {
        url = filter.querySelector('a.current').getAttribute('remote-href');
      } else{
        url = filter.querySelector('a.current').getAttribute('href');
      }
      url = new URL(url, document.baseURI);
      let params = new URLSearchParams(url.search);
      params.append('page', urlData.page);
      url.search = params.toString();
    }
    return url;
  }

  static fetchListings(urlData, container, onComplete) {
    const url = DashboardUtils.getUrl(urlData, container);
    
    fetch(url, {headers: {'Content-Type': 'application/json'}}).then(FetchUtils.checkResponseStatus)
      .then((resp) => {
        return resp.json();
      }).then(data => {
        if (!data.page) {
          $('#listings_box').html(data.list);
          document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#listings_box'}}));
        } else if (data.append_listings) {
          document.querySelector('#listings .js-infinite-list-inner').insertAdjacentHTML('beforeend', data.list);
        } else {
          DashboardUtils.hideLoader(container);
          document.querySelector('.js-listings-infinite-list').dispatchEvent(new CustomEvent('infinite-loader:disconnect'));
        }

        if (onComplete) {
          onComplete();
        }

        $(document)[0].dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#listings_box', keepOpened: true}}));
      }).catch((err) => {
        DashboardUtils.hideLoader(document.querySelector('#listings_box'));
        FetchUtils.handleResponseError(err);
      });
  }

  static fetchShowings(urlData, container, onComplete) {
    const url = DashboardUtils.getUrl(urlData, container);
    const showingWrapperSelector = '#showings_box';
    
    fetch(url, {headers: {'Content-Type': 'application/json'}}).then(FetchUtils.checkResponseStatus)
      .then((resp) => {
        return resp.json();
      }).then(data => {
        if (!data.page) {
          $(showingWrapperSelector).html(data.list);
          $('#buttons ul').remove();
          $('#buttons').prepend(data.buttons);
          document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: showingWrapperSelector}}));
        } else if (data.append_showings) {
          document.querySelector(`#${data.showings_container_id} .js-infinite-list-inner`).insertAdjacentHTML('beforeend', data.list);
        } else {
          DashboardUtils.hideLoader(document.querySelector(showingWrapperSelector));
          document.querySelector(`#${data.showings_container_id}.js-showings-infinite-list`).dispatchEvent(new CustomEvent('infinite-loader:disconnect'));
        }
        if (typeof window.toggleLeadInitial !== 'undefined') {
          window.toggleLeadInitial();
        }

        if (onComplete) {
          onComplete();
        }
        $(document)[0].dispatchEvent(new CustomEvent('html-updated', {detail: {container: showingWrapperSelector, keepOpened: true}}));
      }).catch((err) => {
        DashboardUtils.hideLoader(document.querySelector(showingWrapperSelector));
        FetchUtils.handleResponseError(err);
      });
  }
}

export default DashboardUtils;